<template>
  <SocialAnalytics :uuid="uuid" platform="spotify" />
  <ListenersByCountry />
  <NewSpotifyFollowers />
  <SpotifyPopularity />
  <Top10Tracks />
</template>

<script>
import { useRoute } from "vue-router";
import ListenersByCountry from "../components/ListenersByCountry";
import NewSpotifyFollowers from "@/artists/components/NewSpotifyFollowers";
import SocialAnalytics from "../components/SocialAnalytics";
import SpotifyPopularity from "@/artists/components/SpotifyPopularity";
import Top10Tracks from "../components/Top10Tracks";
export default {
  components: {
    ListenersByCountry,
    NewSpotifyFollowers,
    SocialAnalytics,
    SpotifyPopularity,
    Top10Tracks,
  },
  name: "Spotify",
  setup() {
    const route = useRoute();
    const uuid = route.params.uuid;

    return { uuid };
  },
};
</script>

<style scoped></style>
